// log
import store from "../store";


const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};


export const fetchData = (walletaddress) => {
  
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      
        let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
        let totalRoyalties = await store
        .getState()
        .blockchain.smartContract.methods.totalRoyalties()
        .call();
        let getRoyalties = await store
        .getState()
        .blockchain.smartContract.methods.getRoyalties(walletaddress)
        .call();
        let balanceOf = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf(walletaddress)
        .call();
        let mintPrice = await store
        .getState()
        .blockchain.smartContract.methods.mintPrice(walletaddress)
        .call();
        
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          totalRoyalties,
          getRoyalties,
          balanceOf,
          mintPrice,

          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
