import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Gradient from "rgt";
import { useNavigate } from "react-router";



export const SytledInput = styled.input`
 
  font-size: 23px;
  padding: 10px;
  width: 75px;
  margin: 10px;
  height: 50px;
  color: #00dfd8;
  outline: none;
  background: #111;
  border: #ff0000;
  border-radius: 10px;
  border-color: #ff0000;
  box-shadow: 0 0 10px #719ece;
`;

export const StyledButton = styled.button`
  font-size: 22px;
  width: 220px;
  height: 50px;
  border: #ff0000;
  box-shadow: 0 0 10px #719ece;
  outline: none;
  color: #00dfd8;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:active {
    color: #000;
  }

  &:active:after {
    background: transparent;
  }

  &:hover:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

function MintClaim() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [claimroyaltiesnow, setClaimRoyaltiesnow] = useState(false);
  const [feedback, setFeedback] = useState("Cost: 250 CRO, OG Holder: 200 CRO");
  const Web3Utils = require('web3-utils');
  const BigNumber = require('bignumber.js');
  const [numentry, setnumentry] = useState("");
  const [CONFIG, SET_CONFIG] = useState({});
  
  const navigate = useNavigate();

  const claimroyal = () => {
  const isRoyaltiesEnough = data.getRoyalties >= 1000000000000000000;
  const gasLimit = isRoyaltiesEnough ? CONFIG.CLAIM_GAS_LIMIT : CONFIG.CLAIM2_GAS_LIMIT;
  const chainID = CONFIG.chainID;
  const totalGasLimit = String(gasLimit);
  console.log("Gas limit: ", totalGasLimit);
  setFeedback(CONFIG.CLAIMING);
  setClaimRoyaltiesnow(true);
  blockchain.smartContract.methods
    .claimAllRoyalties()
    .send({
      gasLimit: totalGasLimit,
      address: blockchain.account,
      ID: String(chainID),
      to: CONFIG.CONTRACT_ADDRESS,
      from: blockchain.account,
    })
    .once("error", (err) => {
      console.log(err);
      setFeedback(CONFIG.CLAIMERROR);
      setClaimRoyaltiesnow(false);
    })
    .then((receipt) => {
      console.log(receipt);
      setFeedback(CONFIG.CLAIMSUCCESS);
      setClaimRoyaltiesnow(false);
      dispatch(fetchData(blockchain.account));
    });
  };

  const claimNFTs = () => {
    if (numentry >= 1) {
      let cost = data.mintPrice;
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = new BigNumber(Web3Utils.toWei(new BigNumber(cost * numentry).toFixed()));
      totalCostWei = totalCostWei.dividedBy(new BigNumber(10).pow(18)).toFixed();
      let totalGasLimit = String(gasLimit * numentry);
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(CONFIG.MINTING);
      setClaimingNft(true);
      blockchain.smartContract.methods
        .mint(numentry)
        .send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback(CONFIG.MINTERROR);
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(CONFIG.MINTSUCCESS);
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        });
    } else {
      alert("Please select an amount to mint!");
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchain.account]);

  return (
    <s.Screen>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container ai={"center"} jc={"center"}>
          <s.Container1
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",

              maxHeight: "550px",
              minHeight: "250px",
              maxWidth: "400px",
              minWidth: "250px",
            }}
          >
            <StyledButton
              style={{ fontSize: 20, marginTop: 50 }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
                navigate("./GatedContent");
              }}
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                {claimingNft ? "Connecting!" : "Connect Your Wallet"}
              </Gradient>
            </StyledButton>
            <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
              <s.TextDescription
                style={{ fontSize: 22, marginBottom: 10, textAlign: "center" }}
              >
                Follow the music to protect your investment!
              </s.TextDescription>
            </Gradient>
          </s.Container1>
          {blockchain.errorMsg !== "" ? (
            <>
              <s.SpacerSmall />
              <s.TextDescription
                style={{ textAlign: "center", color: "red", fontSize: 25 }}
              >
                {blockchain.errorMsg}
              </s.TextDescription>
            </>
          ) : null}
        </s.Container>
      ) : (
        <s.Container ai={""} jc={""}>
          {Number(data.totalSupply) === 4454 ? (
            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <s.Container1
                ai={"center"}
                jc={"center"}
                fd={"column"}
                style={{
                  marginLeft: "0px",
                  marginBottom: "0px",
                  marginTop: "10px",
                  borderRadius: "8px",
                  flexWrap: "wrap",

                  maxHeight: "550px",
                  minHeight: "250px",
                  //maxWidth: "1920px",
                  minWidth: "400px",
                }}
              >
                <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                  <s.TextTitle
                    style={{
                      fontFamily: "BTFD",
                      textAlign: "center",
                      fontSize: "35px",
                      marginLeft: "0px",
                      marginTop: "7px",
                    }}
                  >
                    The sale has ended!
                  </s.TextTitle>
                </Gradient>
                <s.SpacerSmall />
                <s.TextDescription
                  style={{
                    fontFamily: "BTFD",
                    textAlign: "center",
                    fontSize: "30px",
                    marginLeft: "0px",
                  }}
                >
                  <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                    Get {CONFIG.NFT_NAME} on{""}
                  </Gradient>{" "}
                  {""}
                  <a
                    href={CONFIG.MARKETPLACE_LINK}
                    style={{ color: "#007CF0", textDecoration: "none" }}
                  >
                    {CONFIG.MARKETPLACE}
                  </a>
                </s.TextDescription>
                <s.SpacerSmall />
              </s.Container1>
              <s.Container1
                    ai={"center"}
                    jc={"center"}
                    fd={"column"}
                    style={{
                      marginLeft: "20px",
                      marginBottom: "0px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap",
                      maxHeight: "550px",
                      minHeight: "250px",
                      minWidth: "400px",
                    }}
                  >
                    <s.SpacerSmall />
                    <StyledButton
                      disabled={claimroyaltiesnow ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimroyal();
                        getData();
                      }}
                    >
                      <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                        <text style={{ fontFamily: "BTFD", fontSize: 35 }}>
                          {claimroyaltiesnow ? "Claiming" : "Claim"}
                        </text>
                      </Gradient>
                    </StyledButton>
                    <s.SpacerMedium />

                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          fontSize: 27,
                          fontFamily: "BTFD",
                        }}
                      >
                        <br></br>
                        {parseFloat(data.totalRoyalties / 10 ** 18).toFixed(
                          2
                        )}{" "}
                        Total Distributed
                        <br></br>
                        {parseFloat(data.getRoyalties / 10 ** 18).toFixed(
                          2
                        )}{" "}
                        Available To Claim
                        
                      </s.TextDescription>
                    </Gradient>
                  </s.Container1>
            </s.Container>
          ) : (
            <s.Container ai={""} jc={""}>
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription
                    style={{ textAlign: "center", fontSize: 20 }}
                  >
                    Connect to the CRO network
                  </s.TextDescription>

                  {blockchain.errorMsg !== "Connect to CRO network" ? (
                    <>
                      <s.TextDescription style={{ textAlign: "center" }}>
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <s.Container
                  ai={""}
                  jc={"center"}
                  fd={"row"}
                  style={{
                    marginLeft: "0px",
                    marginBottom: "0px",
                    marginTop: "0px",
                  }}
                >
                  {/* MINT SECTION */}
                  <s.Container1
                    ai={"center"}
                    jc={"center"}
                    fd={"column"}
                    style={{
                      marginLeft: "0px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap",
                      maxHeight: "550px",
                      minHeight: "250px",
                      //maxWidth: "1920px",
                      minWidth: "400px",
                    }}
                  >
                    <s.SpacerSmall />
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(numentry);
                        getData();
                      }}
                    >
                      <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                        <text style={{ fontFamily: "BTFD", fontSize: 35 }}>
                          {claimingNft ? "Minting" : "Mint"}
                        </text>
                      </Gradient>
                    </StyledButton>
                    <s.SpacerXXXSmall />

                    <SytledInput
                      id="num"
                      type="number"
                      min="1"
                      max={CONFIG.MAXMINTAMOUNT}
                      placeholder="0"
                      onChange={(e) => setnumentry(e.target.value)}
                    ></SytledInput>
                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          fontSize: 27,
                          fontFamily: "BTFD",
                        }}
                      >
                        {feedback} <br /> {data.totalSupply}/4454 Minted
                      </s.TextDescription>
                    </Gradient>
                  </s.Container1>
                  <s.Container1
                    ai={"center"}
                    jc={"center"}
                    fd={"column"}
                    style={{
                      marginLeft: "20px",
                      marginBottom: "0px",
                      marginTop: "10px",
                      borderRadius: "10px",
                      flexWrap: "wrap",
                      maxHeight: "550px",
                      minHeight: "250px",
                      minWidth: "400px",
                    }}
                  >
                    <s.SpacerSmall />
                    <StyledButton
                      disabled={claimroyaltiesnow ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimroyal();
                        getData();
                      }}
                    >
                      <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                        <text style={{ fontFamily: "BTFD", fontSize: 35 }}>
                          {claimroyaltiesnow ? "Claiming" : "Claim"}
                        </text>
                      </Gradient>
                    </StyledButton>
                    <s.SpacerMedium />

                    <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          fontSize: 27,
                          fontFamily: "BTFD",
                        }}
                      >
                        <br></br>
                        {parseFloat(data.totalRoyalties / 10 ** 18).toFixed(
                          2
                        )}{" "}
                        Total Distributed
                        <br></br>
                        {parseFloat(data.getRoyalties / 10 ** 18).toFixed(
                          2
                        )}{" "}
                        Available To Claim
                      </s.TextDescription>
                    </Gradient>
                  </s.Container1>
                </s.Container>
              )}
            </s.Container>
          )}
        </s.Container>
      )}
    </s.Screen>
  );
}
export default MintClaim;
