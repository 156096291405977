import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import i31 from "../assets/images/BTFD.webp";
import i22 from "../assets/images/logo.png";
import i11 from "../assets/images/discord.webp";
import i14 from "../assets/images/metamask.webp";
import i12 from "../assets/images/twitter.webp";
import i23 from "../assets/images/ebisu.webp";
import i24 from "../assets/images/Minted.webp";
import i28 from "../assets/images/cronos.webp";
import i29 from "../assets/images/moonflow.webp";

function LogoLinks() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);

  const [CONFIG, SET_CONFIG] = useState({});
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchain.account]);

  return (
    <s.Container ai={"center"} jc={"center"}>
      <s.Container
        ai={""}
        jc={"center"}
        fd={"row"}
        style={{
          marginTop: "30px",
          marginBottom: "5px",
          marginLeft: "0px",
          flexWrap: "",
          overflow: "",
          maxHeight: "45px",
          minHeight: "45px",
          maxWidth: "140px",
        }}
      >
        <a
          href="https://www.btfdrabbit.com/"
          rel="noreferrer"
          title="OG Collection"
          target="_blank"
        >
          <img
            src={i31}
            alt="OG Collection"
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.DISCORD_LINK}
          rel="noreferrer"
          title={CONFIG.DISCORDICONMSG}
          target="_blank"
        >
          <img
            src={i11}
            alt={CONFIG.DISCORD}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.TWITTER_LINK}
          rel="noreferrer"
          title={CONFIG.TWITTERICONMSG}
          target="_blank"
        >
          <img
            src={i12}
            alt={CONFIG.TWITTER}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.CONTRACTSCAN_LINK}
          rel="noreferrer"
          title={CONFIG.CONTRACTSCANICONMSG}
          target="_blank"
        >
          <img
            src={i28}
            alt={CONFIG.CONTRACTSCAN}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.ALTMARKETPLACE_LINK2}
          rel="noreferrer"
          title={CONFIG.ALTMARKETPLACEICONMSG2}
          target="_blank"
        >
          <img
            src={i24}
            alt={CONFIG.ALTMARKETPLACE2}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.MARKETPLACE_LINK}
          rel="noreferrer"
          title={CONFIG.MARKETPLACEICONMSG}
          target="_blank"
        >
          <img
            src={i29}
            alt={CONFIG.MARKETPLACE}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href={CONFIG.ALTMARKETPLACE_LINK}
          rel="noreferrer"
          title={CONFIG.ALTMARKETPLACEICONMSG}
          target="_blank"
        >
          <img
            src={i23}
            alt={CONFIG.ALTMARKETPLACE}
            width="45"
            height="45"
            border="0"
          />
        </a>
        &nbsp;
        <a
          href="https://chainlist.org/?search=cronos"
          rel="noreferrer"
          title="Add Cronos to Metamask"
          target="_blank"
        >
          <img src={i14} alt="Metamask" width="45" height="45" border="0" />
        </a>
      </s.Container>
      <s.SpacerMedium />
      <img
        src={i22}
        height="211.88px"
        width="182.55px"
        meta
        name="viewport"
        alt=""
      />
    </s.Container>
  );
}
export default LogoLinks;
