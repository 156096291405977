import React from "react";
import * as s from "../styles/globalStyles";
import Gradient from "rgt";
import i3 from "../assets/images/Pigstand.webp";
import i4 from "../assets/images/Didi.webp";
import i5 from "../assets/images/Oki.webp";
import ReactRoundedImage from "react-rounded-image";

function Team() {
  return (
    <s.Container ai={"center"} jc={"center"}>
      <s.SpacerSmall />
      <s.SpacerLarge />
      <s.SpacerSmall />
      <s.Container
        ai={""}
        jc={"center"}
        fd={"row"}
        style={{
          flexWrap: "wrap",
        }}
      >
        {/* Didi */}
        <s.Container4
          ai={"center"}
          jc={""}
          fd={"column"}
          style={{
            marginLeft: "0px",
            marginBottom: "0px",
            marginTop: "10px",
            borderRadius: "10px",
            flexWrap: "wrap",
            maxHeight: "350px",
            minHeight: "350px",
            maxWidth: "256px",
            minWidth: "256px",
          }}
        >
          <a
            href="https://twitter.com/BTFD_ImDiDi"
            rel="noreferrer"
            target="_blank"
          >
            <ReactRoundedImage
              image={i4}
              borderRadius="6"
              roundedColor=""
              imageWidth="250"
              imageHeight="250"
              roundedSize=""
              hoverColor=""
            />
          </a>
          <s.SpacerSmall />
          <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
            <s.TextDescription style={{ fontSize: 18, fontFamily: "BTFD" }}>
              Anthony "Didi" DIDIana
            </s.TextDescription>
          </Gradient>
          <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
            <s.TextDescription style={{ fontSize: 20, fontFamily: "BTFD" }}>
              Founder
            </s.TextDescription>
          </Gradient>
        </s.Container4>

        <s.SpacerMedium />
        {/* OkiemJednym */}
        <s.Container4
          ai={"center"}
          jc={""}
          fd={"column"}
          style={{
            marginLeft: "0px",
            marginBottom: "0px",
            marginTop: "10px",
            borderRadius: "10px",
            flexWrap: "wrap",
            maxHeight: "350px",
            minHeight: "350px",
            maxWidth: "256px",
            minWidth: "256px",
          }}
        >
          <a
            href="https://twitter.com/OkiemJednym_"
            rel="noreferrer"
            target="_blank"
          >
            <ReactRoundedImage
              image={i5}
              borderRadius="6"
              roundedColor=""
              imageWidth="250"
              imageHeight="250"
              roundedSize=""
              hoverColor=""
            />
          </a>
          <s.SpacerSmall />
          <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
            <s.TextDescription style={{ fontSize: 18, fontFamily: "BTFD" }}>
              Paulina "Oki" Krajewska
            </s.TextDescription>
          </Gradient>
          <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
            <s.TextDescription style={{ fontSize: 20, fontFamily: "BTFD" }}>
              Artist
            </s.TextDescription>
          </Gradient>
        </s.Container4>

        <s.SpacerMedium />
        {/* DerabbitagsNFT */}
        <s.Container4
          ai={"center"}
          jc={""}
          fd={"column"}
          style={{
            marginLeft: "0px",
            marginBottom: "0px",
            marginTop: "10px",
            borderRadius: "10px",
            flexWrap: "wrap",
            maxHeight: "350px",
            minHeight: "350px",
            maxWidth: "256px",
            minWidth: "256px",
          }}
        >
          <a
            href="https://twitter.com/DerabbitagsNFT"
            rel="noreferrer"
            target="_blank"
          >
            <ReactRoundedImage
              image={i3}
              borderRadius="6"
              roundedColor=""
              imageWidth="250"
              imageHeight="250"
              roundedSize=""
              hoverColor=""
            />
          </a>
          <s.SpacerSmall />
          <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
            <s.TextDescription style={{ fontSize: 18, fontFamily: "BTFD" }}>
              Nicholas "Derabbit" Harris
            </s.TextDescription>
          </Gradient>
          <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
            <s.TextDescription style={{ fontSize: 20, fontFamily: "BTFD" }}>
              Dev
            </s.TextDescription>
          </Gradient>
        </s.Container4>
      </s.Container>
    </s.Container>
  );
}
export default Team;
