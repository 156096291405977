import { ethers } from "ethers";
import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import NFTCollection from "./NFTCollection.json";
import { Card, Text } from "@nextui-org/react";
import { nftContract, key, mainnet } from "./settings";
import NukaCarousel from "nuka-carousel";
import Gradient from "rgt";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import ranks from "./ranks.json";
import LazyLoad from 'react-lazyload';
import i22 from "../assets/images/logo.png";



export const StyledButton = styled.button`
  font-size: 22px;
  width: 220px;
  height: 50px;
  border: #ff0000;
  box-shadow: 0 0 10px #719ece;
  outline: none;
  color: #00dfd8;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:active {
    color: #000;
  }

  &:active:after {
    background: transparent;
  }

  &:hover:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

export default function NftPuller() {
  const [nfts, setNfts] = useState([]);
  const [loadingState, setLoadingState] = useState("not-loaded");
  

  useEffect(() => {
    // generateNft();
  }, []);

  async function generateNft() {
    const provider = new ethers.providers.JsonRpcProvider(mainnet);
    const wallet = new ethers.Wallet(key, provider);
    const contract = new ethers.Contract(nftContract, NFTCollection, wallet);
    const itemArray = [];
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    
    if (accounts.length === 0) {
      console.log("No accounts found");
      setLoadingState("loaded");
      return;
    }
  
    const address = accounts[0];
    const supply = await contract.balanceOf(address);
    setLoadingState("Loading");
    let totalSup = parseInt(supply, 16);
  
    for (let i = 0; i < totalSup; i++) {
      if (itemArray.length === 50) {
        break;
      }
      try {
        const tokenId = await contract.tokenOfOwnerByIndex(address, i);
        const rawUri = await contract.tokenURI(tokenId);
        const cleanUri = rawUri.replace("ipfs://", "https://ipfs.io/ipfs/");
        const metadata = await axios.get(cleanUri);
        const { name, image, description } = metadata.data;
        const meta = {
          name,
          img: image.replace("ipfs://", "https://ipfs.io/ipfs/"),
          tokenId: tokenId.toNumber(),
          wallet: address,
          desc: description,
          rank: ranks[tokenId.toNumber()].rank,
        };
        itemArray.push(meta);
      } catch (err) {
        console.error(`Error getting token at index ${i}: ${err}`);
      }
    }
  
    setNfts(itemArray);
    setLoadingState("loaded");
  }

  function handleButtonClick() {
    generateNft();
  }

  if (loadingState === "loaded" && nfts.length === 0) {
    return (
      <s.Container ai={"center"} jc={"center"} fd={"column"}>
        <StyledButton
              style={{ fontSize: 20, marginTop: 20 }}
              onClick={generateNft}
              disabled={loadingState === "loading"}
              
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
              <text style={{ fontFamily: "BTFD", fontSize: 28 }}>
              {loadingState === "loading" ? "Loading..." : "View Your NFTs"}
              </text>
              </Gradient>
            </StyledButton>
            <s.SpacerLarge/>
      <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
        <text
          style={{ fontSize: 40, marginBottom: 10, textAlign: "center", fontFamily: "BTFD" }}
        >
          You dont own any BTFDCRO rabbits, mint some now!
        </text>
      </Gradient>
      </s.Container>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <StyledButton
      style={{ fontSize: 20, marginTop: 20 }}
      onClick={handleButtonClick}
      disabled={loadingState === "loading"}
    >
      <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
        <text style={{ fontFamily: "BTFD", fontSize: 28 }}>
          {loadingState === "loading" ? "Loading..." : "View Your NFTs"}
        </text>
      </Gradient>
    </StyledButton>
      <br />
      <div style={{ width: "940px", height: "264.72px", marginTop: "9px", overflow: "hidden" }}>
        <NukaCarousel
          slidesToShow={
            nfts.length <= 10 ? 5 :
            5
          }
          cellSpacing={10}
          width="50px"
        >
          {nfts.map((nft, i) => {
            return (
              <a
                style={{ textDecoration: "none" }}
                href={nft.img}
                rel="noreferrer"
                target="_blank"
              >
                <Card
                  isHoverable
                  css={{
                    marginRight: "$1",
                    boxShadow: "0px 2px 30px #000000",
                    borderStyle: "inset",
                    borderColor: "#00DFD8",
                    borderRadius: "9px",
                    borderWidth: "3px",
                  }}
                  variant="bordered"
                >
                  <LazyLoad height={200}>
                  <Card.Image src={nft.img} loading="lazy" placeholder={<img src={i22}/>}/>
                  </LazyLoad>
                  <Card.Body css={{ background: "#000000" }}>
                    <Text
                      css={{
                        color: "#00DFD8",
                        fontSize: "16px",
                        fontFamily: "BTFD",
                      }}
                      h2
                    >
                      {nft.name}
                    </Text>
                    <Text
                      css={{
                        color: "#00DFD8",
                        fontSize: "16px",
                        fontFamily: "BTFD",
                      }}
                    >
                      Rank: {nft.rank}
                    </Text>
                  </Card.Body>
                </Card>
              </a>
            );
          })}
        </NukaCarousel>
      </div>
    </div>
  );
}



