import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Gradient from "rgt";
import { useNavigate } from "react-router";

export const SytledInput = styled.input`
  font-size: 22px;
  padding: 10px;
  width: 75px;
  margin: 10px;
  height: 50px;
  color: #00dfd8;
  outline: none;
  background: #111;
  border: #ff0000;
  border-radius: 10px;
  border-color: #ff0000;
  box-shadow: 0 0 10px #719ece;
`;

export const StyledButton = styled.button`
  font-size: 22px;
  width: 220px;
  height: 50px;
  border: #ff0000;
  box-shadow: 0 0 10px #719ece;
  outline: none;
  color: #00dfd8;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  &:before {
    content: "";
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:active {
    color: #000;
  }

  &:active:after {
    background: transparent;
  }

  &:hover:before {
    opacity: 1;
  }

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

function MintClaim() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft] = useState(false);
  const [setgatedLogin] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({});
  const navigate = useNavigate();
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchain.account]);

  return (
    <s.Screen>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container ai={"center"} jc={"center"}>
          <s.Container1
            ai={"center"}
            jc={"center"}
            fd={"row"}
            style={{
              marginLeft: "0px",
              marginBottom: "0px",
              marginTop: "10px",
              borderRadius: "10px",
              flexWrap: "wrap",

              maxHeight: "550px",
              minHeight: "250px",
              maxWidth: "400px",
              minWidth: "250px",
            }}
          >
            <StyledButton
              style={{ fontSize: 20, marginTop: 50 }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
              }}
            >
              <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                <text style={{ fontFamily: "BTFD", fontSize: 30 }}>
                  {claimingNft ? "Connecting!" : "Connect Wallet!"}
                </text>
              </Gradient>
            </StyledButton>
            <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
              <s.TextDescription
                style={{
                  fontSize: 28,
                  marginBottom: 10,
                  textAlign: "center",
                  fontFamily: "BTFD",
                }}
              >
                Didi says connect your wallet!
              </s.TextDescription>
            </Gradient>
          </s.Container1>
          {blockchain.errorMsg !== "" ? (
            <>
              <s.SpacerSmall />
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "red",
                  fontSize: 25,
                  fontFamily: "BTFD",
                }}
              >
                {blockchain.errorMsg}
              </s.TextDescription>
            </>
          ) : null}
        </s.Container>
      ) : (
        <s.Container ai={""} jc={""}>
          {Number(data.totalSupply) === 4444 ? (
            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <s.Container1
                ai={"center"}
                jc={"center"}
                fd={"column"}
                style={{
                  marginLeft: "0px",
                  marginBottom: "0px",
                  marginTop: "10px",
                  borderRadius: "8px",
                  flexWrap: "wrap",

                  maxHeight: "550px",
                  minHeight: "250px",
                  //maxWidth: "1920px",
                  minWidth: "400px",
                }}
              >
                <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                      marginLeft: "0px",
                      marginTop: "7px",
                    }}
                  >
                    The sale has ended!
                  </s.TextTitle>
                </Gradient>
                <s.SpacerSmall />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    fontSize: "25px",
                    marginLeft: "0px",
                  }}
                >
                  <Gradient dir="left-to-right" from="#00DFD8" to="#007CF0">
                    Get {CONFIG.NFT_NAME} on{""}
                  </Gradient>{" "}
                  {""}
                  <a
                    href={CONFIG.ALTMARKETPLACE_LINK}
                    style={{ color: "#007CF0", textDecoration: "none" }}
                  >
                    {CONFIG.ALTMARKETPLACE}
                  </a>
                </s.TextDescription>
                <s.SpacerSmall />
              </s.Container1>
            </s.Container>
          ) : (
            <s.Container ai={""} jc={""}>
              {blockchain.account === "" ||
              blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription
                    style={{ textAlign: "center", fontSize: 20 }}
                  >
                    Connect to the ETH network
                  </s.TextDescription>

                  {blockchain.errorMsg !== "Connect to ETH network" ? (
                    <>
                      <s.TextDescription style={{ textAlign: "center" }}>
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <s.Container ai={""} jc={""} fd={""}>
                  {(setgatedLogin === true) & navigate("./GatedContent")}
                </s.Container>
              )}
            </s.Container>
          )}
        </s.Container>
      )}
    </s.Screen>
  );
}
export default MintClaim;
