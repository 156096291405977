import { Route, Routes } from 'react-router-dom'
import GatedContent from './components/GatedContent'
import Home from './components/Home'
import MintClaim from './components/MintClaim'

function App() {
  return (
    <>
      <Routes basename='/index.js'>
      <Route path="/GatedContent" element={<GatedContent/>} />
      <Route path="/MintClaim" element={<MintClaim/>} />
        <Route path="/" element={<Home />}>     
        </Route>
      </Routes>
    </>
  )
}

export default App